import React from "react";
import axios from 'axios';
import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { Button } from "antd";
import { CloseOutlined } from '@ant-design/icons';

import Certificate from "../types/Certificate";
import { baseUrl } from "../BaseUrl";

function CertificateDetails() {
    const { id } = useParams()
    const [certificate, setCertificate] = useState<Certificate>();

    useEffect(() => {
        const GetCertificateById = async () => {
            const response = await axios.get(baseUrl + '/api/Certificates/' + id, { withCredentials: true });
            setCertificate(response.data)
        };

        GetCertificateById();
    }, [id]);

    function handleDelete(sertificateId: string, fileName: string) {
        const DelFileByName = async () => {
            await axios.delete(baseUrl + '/api/Certificates/' + sertificateId + '/RemoveFile/' + fileName, { withCredentials: true })
            .then(() => {
                axios.get(baseUrl + '/api/Certificates/' + id, { withCredentials: true }).then((response) => {
                    setCertificate(response.data);
                });
            });
        };
        DelFileByName();
    }

    return certificate != null ? (
        <div className="page-container">
            <Helmet>
                <title>{"Сертификат " + certificate?.name}</title>
            </Helmet>

            <div style={{ margin: "10px" }} className="p-2 border rounded-4">
                <ListGroup as="ul" >

                        <h4>
                            {certificate?.name}
                        </h4>

                    <div
                        className="d-flex justify-content-between align-items-start"
                        style={{ flexDirection: "column" }}
                    >
                        <div className="images-container">
                            {certificate.photos === undefined || certificate.photos.length === 0 ? <div>Нет приложений</div> :
                            certificate.photos?.map((photo, index) => (
                                <div key={index} className="image-wrapper">
                                    <Button onClick={() => handleDelete(certificate.id, photo.name)} danger icon={<CloseOutlined />} shape="circle" style={{ position: "absolute", right: "-22px", top: "-22px" }}/>
                                    <a id="myLink" href={photo.url as string}>
                                        <img src={photo.url as string} alt={photo.name} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                </ListGroup>
            </div>
        </div>
    ) : <div/>;
}

export default CertificateDetails;