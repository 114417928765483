import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import { ListGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Collapse } from 'antd';

import { Income, Item } from '../types/Income';
import Certificate from '../types/Certificate';
import { baseUrl } from '../BaseUrl';

export default function IncomeDetails() {
    const { id } = useParams();

    const [income, setIncome] = useState<Income>();

    useEffect(() => {
        const getIncome = async () => {
            const response = await axios.get(baseUrl + "/api/Incomes/" + id,
                { withCredentials: true }
            );
            response.data.incomeDate = new Date(response.data.incomeDate);
            setIncome(response.data)
        };

        getIncome();
    }, [id]);


    const [linkedCertificates, setLinkedCertificates] = useState<Map<Item, Certificate>>(new Map<Item, Certificate>());

    const getLinkedCertificate = async (certificateId: string) => {
        const response = await axios.get(baseUrl + "/api/Certificates/" + certificateId,
            { withCredentials: true }
        );
        return response.data as Certificate;
    };
    
    const loc = useRef(linkedCertificates);
    useEffect(() => {
        const loadLinkedCertificates = async () => {
            if (income == null) {
                return;
            }
            for (const item of income.items) {
                const cert = await getLinkedCertificate(item.certificateId);
                setLinkedCertificates(new Map(loc.current.set(item, cert)));
            }
        };

        loadLinkedCertificates();
    }, [income]);




    return income != null ? (
        <div className='page-container'>
            <Helmet>
                <title>{"Поступлениe " + income.incomeDate.toLocaleDateString()}</title>
            </Helmet>

            <h3 style={{ textAlign: "center", margin: "20px" }}>Поступлениe {income.incomeDate.toLocaleDateString()}</h3>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <Box sx={{ height: "100%", width: '100%' }}>
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Описание</div>
                                {income.description}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Время поступления</div>
                                {income.incomeDate.toLocaleTimeString()}
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <div className="fw-bold" style={{ margin: "10px" }}>Предметы: {income.items?.length}</div>
                            <ListGroup>
                                {income.items?.map((item) => (
                                    <ListGroup style={{ margin: "10px", border: "1px solid black" }}>
                                        <Collapse items={
                                            [
                                                {
                                                    key: '1',
                                                    label: <div style={{ display: "flex", alignItems: "center" }}>
                                                        <h4 style={{ textAlign: "left", margin: "5px", width: "60%" }}>
                                                            {item.name}
                                                        </h4>
                                                        <div className="ms-2 me-auto" style={{ width: "20%" }}>
                                                            <div>Количество: {item.quantity}</div>
                                                        </div>
                                                        <div className="ms-2 me-auto" style={{ width: "20%" }}>
                                                            <div>Фото: {item.photoUrls?.length}</div>
                                                        </div>
                                                        <div className="ms-2 me-auto" style={{ width: "20%" }}>
                                                            <div>Сертификат: {<Link to={"/Certificates/" + item.certificateId}>{linkedCertificates.get(item)?.name}</Link>}</div>
                                                        </div>
                                                    </div>,

                                                    children: <ListGroup.Item>
                                                        <div className="ms-2 me-auto">
                                                            <div className="images-container">
                                                                {item.photoUrls === undefined || item.photoUrls.length === 0 ? <div>Нет приложений</div> :
                                                                    item.photoUrls?.map((url, index) => (
                                                                        <div key={index} className="image-wrapper">
                                                                            <img src={url} alt="Certificate" />
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                }
                                            ]
                                        }>
                                        </Collapse>

                                    </ListGroup>
                                ))}
                            </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>
                </Box>
            </div >
        </div >
    ) : null
}