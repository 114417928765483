import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { AxiosResponse } from 'axios';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { Button } from 'antd';
import { Alert } from 'react-bootstrap';

import { baseUrl } from '../BaseUrl';
import { ColumnsType } from 'antd/es/table';
import Certificate from '../types/Certificate';

const CertificatesTable = () => {
    const [certificates, setCertificates] = useState([]);
    const [isErrorLinked, setIsErrorLinked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const fetchCertificates = async () => {
        const response = await axios.get(baseUrl + "/api/Certificates",
            { withCredentials: true }
        );
        setCertificates(response.data)
    };

    useEffect(() => {
        fetchCertificates();
    }, []);

    const handleDelete = async (id: string) => {
        await axios.delete(baseUrl + "/api/Certificates/Delete/" + id,
            { withCredentials: true }

        ).then((resp : AxiosResponse) => {

            if (resp.status === 200)
                fetchCertificates();
            setErrorMessage(resp.data);

        }).catch((error : AxiosError) => {
        
            if (error.response && error.response.data)
            {
                setErrorMessage(error.response.data as string);
                setIsErrorLinked(true);
            }
        })
        fetchCertificates();
    };

    const columns: ColumnsType<Certificate> = [
        {
            dataIndex: 'name',
            title: 'Название сертификата',
            width: "100%",
            render: (text, record) => (
                <Button type='link' onClick={() => navigate("/Certificates/" + record.id)}>{text}</Button>
            )
        },
        {
            render: (_, record : Certificate) => (
                <Button danger onClick={() => handleDelete(record.id)}>Удалить</Button>
            ),
        }

    ];
    return (
        <div className='page-container'>
            <h3 style={{ textAlign: "center", margin: "20px" }}>Сертификаты</h3>
            <div style={{ display: "flex", alignItems: "center", margin: "20px", justifyContent: "center" }}>
                <Box sx={{ height: "100%", width: '100%' }}>
                    <Table
                        dataSource={certificates}
                        columns={columns}
                    />
                    <Button type='primary' onClick={() => navigate("/Certificates/Create")} style={{ marginTop: "20px" }}>
                        Создать сертификат
                    </Button>
                </Box>
            </div>

            <div style={{ position: "absolute", left: "50%", top: "50%", minWidth: "20%", transform: "translate(-50%, -50%)" }}>
                <Alert show={isErrorLinked} variant="danger">
                    <Alert.Heading>Ошибка</Alert.Heading>
                    <p>
                        {errorMessage}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setIsErrorLinked(false)} type="primary">
                            Закрыть
                        </Button>
                    </div>
                </Alert>
            </div>
        </div>
    );
};

export default CertificatesTable;