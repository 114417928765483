import React from "react";
import axios from 'axios';
import { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";

import Certificate from "../types/Certificate";
import PhotosInfo from "../types/PhotosInfo";
import { baseUrl } from "../BaseUrl";

function CreateCertificate() {

    const [certificate, setCertificate] = useState<Certificate>({ id: '', photos: [], name: '' });
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const [validated, setValidated] = useState(false)
    const [success, setSuccess] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', certificate.name);
        if (certificate.photos) {
            for (let i = 0; i < certificate.photos.length; i++) {
                formData.append('files', certificate.photos[i].url as File);
            }
        }

        try {
            const response = await axios.post(baseUrl + '/api/certificates', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });

            setMessage('Сертификат успешно загружен: ' + response.data);
            setSuccess(true);
            setValidated(true);
        } catch (error: any) {
            setValidated(false);
            setMessage('Ошибка загрузки сертификата: ' + (error.response ? error.response.data : 'Ошибка сети'));
        }
    };

    function filesToPhotos(files: File[]) : PhotosInfo[] {
        return files.map(file => ({ name: file.name, url: file } as PhotosInfo));
    }

    function setSelectedFiles(files: File[]) {
        setCertificate({ ...certificate, photos: filesToPhotos(files) });
    }

    const handleFileChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
        const oldFiles = certificate.photos.map(photo => photo.url as File);
        setSelectedFiles([...oldFiles, ...Array.from(event.target.files as FileList) as File[]]);
    };

    const handleFileRemove = (index : number) => {
        const newFiles = [...certificate.photos].map(photo => photo.url as File);
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles as File[]);
    };

    const endCreation = () => {
        setSuccess(false);
        navigate('/Certificates');
    }

    return (
        <div className="page-container">
            <Form validated={validated} onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: 'auto', marginTop: '100px' }}>
                <Form.Group className="mb-3" >
                    <Form.Label>Название сертификата</Form.Label>
                    <Form.Control required type="text" value={certificate.name} onChange={(event) => setCertificate({ ...certificate, name: event.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Приложения</Form.Label>
                    <Form.Control type="file" multiple onChange={handleFileChange} />
                    <ListGroup>
                        {certificate.photos.map((file, index) => {
                            if (typeof file === 'string') {
                                return <ListGroup.Item key={index}>{file}</ListGroup.Item>;
                            }
                            return (
                                <ListGroup.Item key={index}>
                                    {file.name}
                                    <Button style={{ marginLeft: "20px" }} variant="secondary" onClick={() => handleFileRemove(index)}>Удалить</Button>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Создать
                </Button>
                {message && <p>{message}</p>}
            </Form>

            <div style={{ position: "absolute", right: "40%", top: "35%", minWidth: "20%" }}>
                <Alert show={success} variant="success">
                    <Alert.Heading>Успешно</Alert.Heading>
                    <p>
                        Сертификат добавлен
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={endCreation} variant="outline-success">
                            Закрыть
                        </Button>
                    </div>
                </Alert>
            </div>
        </div>


    );
}

export default CreateCertificate;